@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

html, body {
    height: 100%;
    min-height: 100%;
    font-family: 'Montserrat', sans-serif;
    background-color: rgb(240, 244, 247);
}

a:hover, a:visited {
    color: #922d0f;
}

a {
    color: #e74415;
}

footer {
    position: absolute;
    bottom: 0;
    padding: 20px;
    background-color: #e74415;
    width: auto;
    left: 0;
    right: 0;
    color: #fff
}

.MuiButton-root {

}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
}

.MuiButton-root.MuiButton-contained.MuiButton-containedWarning {
    background-color: #e74415;
    font-weight: bold;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
    background-color: #e74415;

}

.MuiButton-root.MuiButton-contained.MuiButton-containedSuccess svg {
    color: #fff !important;

}

.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
    background-color: #fff;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.3);
    border-color: #e74415;
    color: #e74415
}

.MuiButton-root.MuiButton-outlined.MuiButton-outlinedWarning {
    background-color: #e74415;
    color: #fff;
}

.MuiButton-root.MuiButton-outlined.MuiButton-outlinedWarning.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
    border: 2px #fff solid;
    border-radius: 10px;
    font-size: 0.875rem;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedWarning.Mui-disabled {
    background-color: #f9d0b7;
    color: #fff;
    box-shadow: 0px 3px 0px 1px #ccc;
    pointer-events: auto;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedError {
    background-color: rgba(255, 255, 255, 0.67);
    border: 2px #e74415 solid;
    border-radius: 10px;
    box-shadow: 0px 3px 0px 1px #a63110;
    font-size: 16px;
    font-weight: bold;
    color: #e74415
}

.MuiButton-root.MuiButton-contained.MuiButton-containedError.Mui-disabled {
    background-color: #f9d0b7;
    border: 2px #fff solid;
    color: #fff;
    box-shadow: 0px 3px 0px 1px #ccc;
    pointer-events: auto;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    border-radius: 5px;
    background-color: #03907f;
    color: #ffffff;
    padding: 4px 10px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:hover {
    background-color: #0ba391;

}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:disabled {
    background-color: #4b4e4e;
    pointer-events: auto;
}

.header__username {
    border-radius: 10px;
    background-color: #922d0f;
    margin-right: 10px;
    display: inline-block;
    margin-right: 10px;
    padding: 10px 15px;
    line-height: 22px;
}

img {
}

.page-main-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rules-main-box {
    display: flex;
    flex-direction: column;
}

.inline-price {
    font-weight: bold;
    margin-right: 2px;
}

.inline-price img {
    width: 16px;
    position: relative;
    top: 2px;
    left: 1px
}

.product-card__price span, .navbar__balance span {
    font-size: 20px;
    font-weight: bold;
}

.navbar__balance {
    padding-top: 4px;
}

.navbar__balance span {
    color: #e74415;
}

.product-card__price img, .navbar__balance img {
    margin-left: 4px;
    width: 16px;
}

.navbar__balance img {
    width: 23px;
}

.MuiTabs-root.menu a {
    font-weight: bold;
}

.MuiTabs-root.menu a > span {
    background-size: 25px;
    background-repeat: no-repeat;
    height: 25px;
    width: 100%;
    background-position: center;
    display: inline-block;
}

.subtabs {
    position: relative;
    bottom: 23px;
}


div.MuiTabs-root {
    height: auto;
    min-height: auto;
}

.MuiButtonBase-root.MuiTab-root {
    min-height: auto;
    margin-right: 12px;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    border: 1px #e74415 solid;
    padding: 5px 7px;
    position: relative;
    z-index: 1;
}

.MuiTabs-scroller .MuiTabs-indicator {
    z-index: 0;
    height: 100%;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary:hover {
    background-color: rgba(231, 68, 21, 0.1);
}

.MuiTabs-flexContainer a.Mui-selected,
.MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: #fff;
    transition-delay: 0.1s;
    transition-duration: 0.1s;
}

.MuiTabs-scroller .MuiTabs-indicator {
    background-color: #e74415;

}

.text-centered {
    text-align: center;
}

.colored-title {
    background-color: #e74415;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    display: inline-block;
    padding: 5px 10px;
    margin: 10px 0;
}

.MuiBox-root {
    align-items: start;
}

.MuiTabs-root {
    padding: 0;
}

.MuiCircularProgress-root.MuiCircularProgress-colorPrimary {
    color: #e74415;
}

.MuiDataGrid-root {
    background: #fff
}

.product-cards {
    margin: 0 -15px;
    width: 100%
}

.product-card.MuiBox-root {
    width: calc(100% * (1 / 4) - 30px);

    margin: 15px;
    padding: 0;
}

.product-card button {
    cursor: pointer
}

.product-page {
    max-width: 800px;
    margin: auto;
    padding: 20px;
}

.product-page .product-card__price {
    margin-bottom: 10px;
}

.error-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #e74415
}

.MuiFormControl-root.navbar__location > .MuiInputBase-formControl {
    width: 100%;
}

.MuiFormControl-root.navbar__location {
    max-width: 200px;
    background-color: #fff
}

.MuiCardActions-root.MuiCardActions-spacing .MuiButton-root {
    float: right;
}

.MuiCardActions-root.MuiCardActions-spacing > .MuiBox-root {
    width: 100%;
}

.MuiCardActions-root.MuiCardActions-spacing {
    padding: 0 16px 16px 16px;

}

.MuiTypography-root.MuiTypography-h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.MuiButtonBase-root.MuiTab-root {
    color: #e77250;
}

.MuiButtonBase-root.variation__item.selected, .MuiButtonBase-root.variation__item.selected:hover {
    background-color: #03907f;
    color: #fff;
}

.MuiButtonBase-root.variation__item.selected svg {
    color: #fff !important;
}

.MuiButtonBase-root.variation__item {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 3px 5px;
    cursor: pointer;
}

.MuiSvgIcon-root {
    /*color: #e77250;*/
}

.MuiDataGrid-root.MuiDataGrid-main.MuiDataGrid-row.Mui-selected {
    background-color: rgba(231, 68, 21, 0.08);
}

.MuiChip-root.MuiChip-filled.MuiChip-sizeSmall.MuiChip-colorWarning {
    background-color: #e74415;
}

.MuiInputLabel-outlined.MuiFormLabel-colorWarning.MuiFormLabel-filled {
    color: #e74415;
    border-color: #e74415;
}

.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorPrimary {
    background-color: #03907f;
    color: #fff;
}

.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorWarning {
    background-color: #e74415;
    color: #fff;
}

.rules-statements {
    background-color: #e74415;
}

.MuiDataGrid-columnHeaders {
    background-color: #e74415 !important;
    --DataGrid-containerBackground: #e74415;
    color: #fff;
}

.MuiDataGrid-cell {
    border-left: 1px solid rgba(224, 224, 224, 1);
}

.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault {
    margin-left: auto;
    background-color: rgba(249, 208, 183, 0.3);
    font-weight: bold;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-icon.MuiChip-iconMedium.MuiChip-iconColorDefault {
    color: #e74415;
}

#root {
    min-height: 100%;
}

@media (max-width: 800px) {
    #root .MuiContainer-root {
        width: 100%;
    }

    .product-card.MuiBox-root {
        width: calc(100% * (1 / 2) - 30px);

        margin: 15px;
        padding: 0;
    }

    .MuiBox-root.navbar {
        display: block;
    }

    .MuiFormControl-root.navbar__location {
        display: block;
        max-width: 350px;
    }

    .MuiFormControl-root.navbar__balance {
        display: block;
    }
}

.belka-login-bg {
    padding: 50px 50px 50px 50px;
    text-align: center;
    vertical-align: bottom;

}

.belka-login-bg img {
    width: 80%;
    cursor: pointer;
    margin: auto;
}

.imageViewer-main {
    padding: 0px;
    margin: -24px;
    margin-bottom: 10px;
}

.imageViewer-other img, .imageViewer-main img {
    cursor: pointer;;
}

.imageViewer-main img {
    width: 100%;
}

.imageViewer-other:after {
    clear: both;
    content: '';
    display: block;
}

.imageViewer-other img {
    width: 100%;
}

.imageViewer-other {
    padding: 5px;
}

.imageViewer-other > div {
    float: left;
    width: 33%;
    height: 120px;
    overflow: hidden;
}

.create-order__image-wrap img {
    width: 100%;
}

.create-order__image-wrap {
    padding: 0 10px 10px 0;
}

img {
    width: 100%;
}

.users-chip {
    display: flex;
    align-content: center;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-icon.MuiChip-iconMedium.MuiChip-iconColorDefault {
    margin-left: 15px;
}

.validation-process {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.img-container.gs {
    filter: grayscale(100%);
}
.img-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 57%;
    filter: grayscale(0%);
    transition: all 0.5s;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.0);

}

.img-container:hover {
    filter: grayscale(0%) !important;
    transform: scale(1.02);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.img-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.switch-mode {
    line-height: 14px;
    font-size: 12px;
}

.switch-mode span {
    border-bottom: 1px #000000 dashed;
    padding: 0 2px 0 0;
    display: inline-block;
}

.switch-mode span:hover {
    color: #e74415;
    cursor: pointer;
    border-bottom-color: #e74415;


}

.switch-mode span.active {
    border-bottom-color: rgba(0, 0, 0, 0);
    font-weight: bold;
    color: #000;
    cursor: default;

}

.MuiBox-root a {
    display: inline-block;
}

.MuiMenuItem-root.active {
    background-color: #f0f0f0
}

.MuiMenuItem-root.pending {
    background-color: #f00
}

div.header {
    padding-bottom: 0;
    position: fixed;
    z-index: 10000;
    width: 100%;

    box-shadow: rgba(50, 50, 93, 0.02) 0 2px 5px -1px, rgba(0, 0, 0, 0.05) 0 1px 3px -1px;;
}

div.header > .MuiAppBar-root {
    box-shadow: none
}

div.games-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%)
}

.wrapper {
    display: flex;
}

.MuiDrawer-root > .MuiDrawer-paper {

    border: 0;
    height: 100%;
    background-color: #fff;

    box-shadow: rgba(50, 50, 93, 0.02) 0 2px 5px -1px, rgba(0, 0, 0, 0.05) 0 1px 3px -1px;;
}

.right-col {
    flex-grow: 1;

}

.wrapper .MuiCard-root {
    box-shadow: rgba(50, 50, 93, 0.02) 0 2px 5px -1px, rgba(0, 0, 0, 0.05) 0 1px 3px -1px;;

}

.MuiTableRow-root {
    border-top-color: #ebedef;
    border-bottom-color: #ebedef;
}

.MuiTable-root .MuiTableCell-root {
    vertical-align: top;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebedef;
}

.MuiTable-root .MuiTableCell-root.MuiTableCell-sizeSmall {
    padding: 2px;
}

.MuiCard-root {
    margin-bottom: 16px;
}

.MuiTabs-root {
    background-color: #fff;
    padding: 16px;
}

div.MuiTabPanel-root {
    padding-top: 16px;
}

.MuiCardHeader-action > button {
    margin-left: 5px;
}

li.MuiListItem-root {
    padding: 0;
}

li.MuiListItem-root > div {
    padding: 3px 16px;
}

.compact-list.MuiList-root {
    margin-left: -16px;
    overflow: hidden;
}
.break-word {
    word-break: break-all;
}


.MuiToolbar-root.toolbarStaging {
    background-color: #fff  !important;
    background: linear-gradient(rgba(255,255,255, 0) .9em, rgba(0, 0, 0, .15) 1em) 0 0,
    linear-gradient(90deg, rgba(255,255,255, 0) .9em, rgba(0, 0, 0, .15) 1em) 0 0;
    background-size: 1em 1em
}
.MuiToolbar-root.toolbarProd {
    background-color: #ffffff !important;
}

.MuiTable-root.striped .MuiTableCell-root {
    border-right: 1px #efefef solid;
}